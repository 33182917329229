
import { CustomerType, IDType, Gender, OrderMessagesDto, OrdersViewModel, MessageType, ApiOrdersActivateOrderPutRequest, ApiOrdersMigrateOrderPutRequest, PagedResultOfOrderPaymentViewModel, QueueStatus, PagedResultOfCustomerOrdersDto, PagedResultOfAuditLogDto, AuditTypes, OrderSubStatesEnum } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import Account from "@/components/Orders/Account.vue";
import Products from "@/components/Orders/Products.vue";
import TopUps from "@/components/Orders/TopUps.vue";
import OtherProducts from "@/components/Orders/OtherProducts.vue";
import Notifications from "@/components/Orders/Notifications.vue";
import AllOrders from "@/components/Orders/AllOrders.vue";
import AuditLogs from "@/components/Orders/AuditLogs.vue";
import Payments from "@/components/Orders/Payments.vue";
import { ordersActivate, ordersMigrate, ordersGetId, ordersGetIdMessages, ordersBypassTelkomSuspend, ordersReactivateOrderPut } from "@/api/orders";
import DialogBox from "@/components/DialogBox.vue";
import { hasClaim } from "@/utils/claims";
import { ordersIdPaymentGet } from "@/api/payments";
import { PropType } from "vue/types/v3-component-props";
import store from "@/store";
import { customersIdOrdersGet } from "@/api/customers";
import { userActivityOrdersIdGet } from "@/api/user-activity";

type Tab = "account" | "products" | "topUps" | "otherProducts" | "notifications" | "payments" | "orders";
type ActionName = "activate" | "suspend" | "migrate" | "reactivate";
type ActionDetail = {
  callback: Function;
  message: string;
  title: string;
}

@Component({
  name: "OrderDetail",
  components: { Account, Products, Notifications, Payments, DialogBox, TopUps, OtherProducts, AllOrders, AuditLogs },
  props: {
    id: { required: true, type: String },
    filter: { type: String },
    pageNumber: { type: String },
    pageSize: { type: String },
    queueStatus: { type: String as PropType<QueueStatus> },
    billingDayOfMonth: { type: String }
  },
})
export default class extends Vue {
  tabData: OrdersViewModel = {
    id: "",
    dateTimeStamp: "",
    cancelledDate: "",
    customersId: "",
    pIMSAgentId: 0,
    pIMSDealerId: 0,
    coverageCheckStatus: "",
    creditCheckStatus: "",
    orderSubStateId: OrderSubStatesEnum.NewOrder,
    statusReason: "",
    billingDay: 1,
    acceptedTsCs: true,
    referenceNumber: "",
    monthlySubscriptionAmount: 0,
    currentQueueHandler: "",
    orderProducts: [],
    customers: {
      id: "",
      dateTimeStamp: "",
      cancelledDate: "",
      firstName: "",
      middleName: "",
      lastName: "",
      idNumber: "",
      idNationality: "",
      emailAddress: "",
      mobileNumber: "",
      additionalNumber: "",
      status: "",
      statusReason: "",
      dateOfBirth: "",
      suspendedDate: "",
      gender: Gender.Male,
      idType: IDType.IdNumber,
      customerType: CustomerType.Customer,
      address: {
        line1: "",
        line2: "",
        line3: "",
        suburb: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      customerPaymentMethods: [],
      usersId: "",
    },
    preferredUpfrontPayementDate: "",
    isSubcription: true,
    key: 0,
    canActivateOrder: false,
    canBypassTelkomSuspend: false,
    canBypassTelkomOrderMigration: false,
    canBypassTelkomResume: false
  };
  paymentsTabData: PagedResultOfOrderPaymentViewModel = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  }
  notificationTabData: OrderMessagesDto[] = [];
  ordersTabData: PagedResultOfCustomerOrdersDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  activityTabData: PagedResultOfAuditLogDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  activeTab: Tab = "account";
  title = "";
  from: 'Search' | 'PaymentsDue' = "Search";
  dialogVisible: boolean = false;
  dialogSelectedAction: ActionName | null = null;
  userHasPermisson = false;
  activationDate: string | null = null;
  activateDateError = false;
  msisdn: string | null = null;
  isMsisdnError: boolean = false;
  productOrderReferenceNumber: string | null = null;
  isProductOrderRefError: boolean = false;
  actions: Record<ActionName, ActionDetail> = {
    "activate": {
      callback:() => this.activateOrder(),
      message: "Have you checked the Telkom OMS system to ensure the user has been activated by Telkom?",
      title: "Confirm Order Activation",
    },
    "suspend": {
      callback:() => this.bypassTelkomSuspendOrder(),
      message: "Are you sure you want to bypass Telkom and suspend this order?",
      title: "Confirm Order Suspension",
    },
    "migrate": {
      callback:() => this.bypassTelkomMigrateOrder(),
      message: "Are you sure you want to bypass Telkom and migrate this order?",
      title: "Confirm Order Migration",
    },
    "reactivate": {
      callback:() => this.bypassTelkomResumeOrder(),
      message: "Are you sure you want to bypass Telkom and reactivate this order?",
      title: "Confirm Order Reactivation",
    },
  }

  get queries() {
    return {
      filter: this.$props.filter,
      pageNumber: this.$props.pageNumber,
      queueStatus: this.$props.queueStatus,
      pageSize: this.$props.pageSize,
      billingDayOfMonth: this.$props.billingDayOfMonth
    }
  }

  get activateConfirmDisabled() {
    return this.activationDate ? false : true
  }

  validateMSISDN(event: Event) {  
    const target = event.target as HTMLInputElement;  
    const value = target.value;  
    this.msisdn = value.replace(/[^0-9]/g, '');
  } 

  validateMSISDNOnBlur() {  
    if (this.msisdn !== null) {  
      this.isMsisdnError = !/^\d+$/.test(this.msisdn);  
    }  
  }  

  validateProductOrderRef(event: Event) {  
    const target = event.target as HTMLInputElement;  
    const value = target.value;  
    this.productOrderReferenceNumber = value.replace(/[^0-9]/g, '');
  } 

  validateProductOrderRefOnBlur() { 
    if (this.productOrderReferenceNumber !== null) {  
      this.isProductOrderRefError = !/^\d+$/.test(this.productOrderReferenceNumber);
    }
  }  

  async created() {
    if(this.$route.query.from == 'payments-due') {
      this.from = "PaymentsDue"
    }

    this.userHasPermisson = await hasClaim("ManageOrders")

    this.loadInitialData();
    this.title = `${this.tabData.customers.firstName} ${this.tabData.customers.lastName}`;

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab as Tab;
    } else {
      this.activeTab = "account";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab as Tab;
      } else {
        this.activeTab = "account";
      }
    });
  }

  
  hasProductType(type: 'LTE' | 'TOP' | undefined = undefined) {
    const product = this.tabData.orderProducts.find(item => type ? item.products.productType == type : !['LTE', 'TOP'].includes(item.products.productType))
    return product ? true : false
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    this.$router.replace({
      name: "OrderDetail",
      params: { id: this.$props.id },
      query: { tab: this.activeTab == "account" ? undefined : this.activeTab },
    });
  }

  handleAction(action: ActionName) {
    this.dialogSelectedAction = action;
    this.dialogVisible = true;
  }

  handleActionClose() {
    this.dialogSelectedAction = null;
    this.dialogVisible = false;
    this.activationDate = null;
    this.activateDateError = false;
    this.msisdn = null;
    this.isMsisdnError = false;
    this.productOrderReferenceNumber = '';
    this.isProductOrderRefError = false;
  }

  async activateOrder() {
    if (this.activationDate && !this.isMsisdnError && !this.isProductOrderRefError) {
      const payload: ApiOrdersActivateOrderPutRequest = {
        orderId: this.tabData.id,
        activationDate: this.activationDate,
        msisdn: this.msisdn, 
        productOrderReferenceNumber: this.productOrderReferenceNumber, 
      }
      await ordersActivate(payload).then(() => {
        this.loadInitialData();
      })
      this.handleActionClose()
    } else {
      this.activateDateError = true;
    }
  }

  bypassTelkomSuspendOrder() {
    ordersBypassTelkomSuspend(this.tabData.id).then(() => {
      this.loadInitialData();
    })
    this.handleActionClose()
  }

  bypassTelkomResumeOrder() {
    ordersReactivateOrderPut(this.tabData.id).then(() => {
      this.loadInitialData();
    })
    this.handleActionClose()
  }

  async bypassTelkomMigrateOrder() {  
  if (this.productOrderReferenceNumber && !this.isMsisdnError && !this.isProductOrderRefError) {  
    const payload: ApiOrdersMigrateOrderPutRequest = {  
      orderId: this.tabData.id,
      activationDate: this.activationDate,  
      msisdn: this.msisdn,   
      productOrderReferenceNumber: this.productOrderReferenceNumber,   
    };
    await ordersMigrate(payload).then(() => {  
      this.loadInitialData();  
    });  
    this.handleActionClose();  
    } else {  
      this.isProductOrderRefError = true;  
    }  
  }  

  async loadInitialData() {
    if (this.$props.id) {
      store.dispatch("misc/setIsLoadingOverride", true);
      await ordersGetId(this.$props.id)
      .then(async (res) => {
        this.title = `${res.customers.firstName} ${res.customers.lastName}`;
        this.tabData = res;

        await store.dispatch("user/addRecentlyViewed", {
          id: this.tabData.id,
          displayName: this.tabData.referenceNumber || "",
          type: "Order"
        })
      })

      await this.loadOrderPayments()
      await this.loadOrders()
      await this.fetchMessages()
      await this.loadAuditLogs()
      store.dispatch("misc/setIsLoadingOverride", false);
    }
  }

  async loadOrderPayments(pageNumber:number = 1, pageSize: number = 20) {
    this.paymentsTabData = await ordersIdPaymentGet(pageNumber, pageSize, this.$props.id)
  }

  async loadOrders(pageNumber:number = 1, pageSize: number = 20) {
    this.ordersTabData = await customersIdOrdersGet(this.tabData.customersId, pageNumber, pageSize)
  }

  async loadAuditLogs(pageNumber:number = 1, pageSize: number = 20, auditLogTypesId: AuditTypes | undefined = undefined,
  filter: string | undefined = undefined,
  sortDirection: 'Asc' | 'Desc' | undefined = undefined) {
    await userActivityOrdersIdGet(pageNumber, pageSize, this.$props.id, auditLogTypesId || undefined, filter || undefined, sortDirection).then(async (res) => {
      this.activityTabData = res;
    });
  }

  async fetchMessages(filter: "" | MessageType = "") {
    await ordersGetIdMessages(this.$props.id, filter)
    .then(async (res) => {
      this.notificationTabData = res;
    })
  }
}
